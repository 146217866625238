export const white = '#FFFFFF'
export const grayUltraLight = '#1b1f230d'
export const grayExtraLight = '#F5F6F7'
export const grayLight = '#CED4DE'
export const gray = '#7d899c'
export const grayDark = '#2D3747'
export const grayExtraDark = '#1D2330'
export const dark = '#13161F'

export const blueLight = '#e9f2fa'
export const blue = '#0B5FFF'
export const skyBlue = '#1FB6FF'
export const negative = '#EB4D4B'





// export const purple = '#4263F7'
// export const purpleDark = '#2810D8'
// export const ocean = '#38D3EC'
// export const orange = 'indianred'

// export const main = '#272833'
// export const primary = ocean
// // export const white = '#FFFFFF'
// // export const grayExtraLight = '#EFF2F7'
// // export const grayLight = '#E5E9F2'
// // export const gray = '#BEC6CB'
// // export const grayDark = '#8492A6'

// export const text = main
// export const link = '#50DED8'
// export const background = white
// export const border = gray
