export const imports = {
  'docs/guide/attributes.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-guide-attributes" */ 'docs/guide/attributes.mdx'
    ),
  'docs/guide/basic-operators.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-guide-basic-operators" */ 'docs/guide/basic-operators.mdx'
    ),
  'docs/guide/augmentations.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-guide-augmentations" */ 'docs/guide/augmentations.mdx'
    ),
  'docs/guide/basic-types.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-guide-basic-types" */ 'docs/guide/basic-types.mdx'
    ),
  'docs/guide/classes.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-guide-classes" */ 'docs/guide/classes.mdx'
    ),
  'docs/guide/comments.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-guide-comments" */ 'docs/guide/comments.mdx'
    ),
  'docs/guide/control-flows.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-guide-control-flows" */ 'docs/guide/control-flows.mdx'
    ),
  'docs/guide/dependencies.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-guide-dependencies" */ 'docs/guide/dependencies.mdx'
    ),
  'docs/guide/enums.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-guide-enums" */ 'docs/guide/enums.mdx'
    ),
  'docs/guide/error-handling.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-guide-error-handling" */ 'docs/guide/error-handling.mdx'
    ),
  'docs/guide/functions.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-guide-functions" */ 'docs/guide/functions.mdx'
    ),
  'docs/guide/import-export.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-guide-import-export" */ 'docs/guide/import-export.mdx'
    ),
  'docs/guide/include.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-guide-include" */ 'docs/guide/include.mdx'
    ),
  'docs/guide/inline-control-flows.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-guide-inline-control-flows" */ 'docs/guide/inline-control-flows.mdx'
    ),
  'docs/guide/keywords.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-guide-keywords" */ 'docs/guide/keywords.mdx'
    ),
  'docs/guide/macro.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-guide-macro" */ 'docs/guide/macro.mdx'
    ),
  'docs/guide/namespaces.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-guide-namespaces" */ 'docs/guide/namespaces.mdx'
    ),
  'docs/guide/typing.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-guide-typing" */ 'docs/guide/typing.mdx'
    ),
  'docs/guide/variable-declarations.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-guide-variable-declarations" */ 'docs/guide/variable-declarations.mdx'
    ),
  'docs/technical/cache.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-technical-cache" */ 'docs/technical/cache.mdx'
    ),
  'docs/technical/compilation.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-technical-compilation" */ 'docs/technical/compilation.mdx'
    ),
  'docs/technical/known-issues.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-technical-known-issues" */ 'docs/technical/known-issues.mdx'
    ),
  'docs/tools/awesome.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-tools-awesome" */ 'docs/tools/awesome.mdx'
    ),
  'docs/tools/cli.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-tools-cli" */ 'docs/tools/cli.mdx'
    ),
  'docs/welcome/about.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-welcome-about" */ 'docs/welcome/about.mdx'
    ),
  'docs/welcome/getting-started.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-welcome-getting-started" */ 'docs/welcome/getting-started.mdx'
    ),
}
